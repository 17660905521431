import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SuccessModal from "./SuccessModal";

const RegistrationForm = ({ isVisitor, isSpeaker, isExhibitor }) => {
  const { t, i18n } = useTranslation();
  const nav = useNavigate();
  const [successModal, setSuccessModal] = useState(false);

  const tog_modal = () => {
    setSuccessModal(!successModal);
    return successModal;
  };

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    website_url: "",
    job_title: "",
    company: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t("registrationForm.firstNameRequired")),
    last_name: Yup.string().required(t("registrationForm.lastNameRequired")),
    email: Yup.string()
      .email(t("registrationForm.invalidEmail"))
      .required(t("registrationForm.emailRequired")),
    phone: Yup.string().required(t("registrationForm.phoneRequired")),
    job_title: Yup.string().required(t("registrationForm.jobTitleRequired")),
    company: Yup.string().required(t("registrationForm.companyRequired")),
    message: Yup.string(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const url = isVisitor
      ? "https://admin.fmexcon.com/api/userRegisterStore"
      : isExhibitor
      ? "https://admin.fmexcon.com/api/sponsorRegisterStore"
      : isSpeaker
      ? "https://admin.fmexcon.com/api/conferanceRegisterStore"
      : "";
    await axios
      .post(url, {
        ...values,
      })
      .then(() => {
        if (isSpeaker) {
          nav(`/${i18n.language}/conditions`);
        } else {
          setSuccessModal(true);
        }
        resetForm();
      })
      .catch(() => {
        toast.error(t("common.submit_error"));
        throw new Error("Unexpected response");
      });
  };

  return (
    <div
      className={`col-span-12 mb-6 ${
        i18n.language === "en" ? "text-left" : "text-right"
      }`}
      dir={i18n.language === "en" ? "ltr" : "rtl"}
    >
      <SuccessModal tog_modal={tog_modal} successModal={successModal} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form className="w-full mx-auto p-4 relative">
            {isExhibitor ? (
              <div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                  <div className="flex flex-col">
                    <Field
                      name="first_name"
                      placeholder={t("registrationForm.firstNamePlaceholder")}
                      className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="flex flex-col">
                    <Field
                      name="last_name"
                      placeholder={t("registrationForm.lastNamePlaceholder")}
                      className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
                <div className="flex flex-col mb-4">
                  <Field
                    name="email"
                    type="email"
                    placeholder={t("registrationForm.emailPlaceholder")}
                    className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                  <div className="flex flex-col" dir="rtl">
                    <PhoneInput
                      country="sa"
                      value={values.phone}
                      onChange={(phone) => setFieldValue("phone", phone)}
                      inputProps={{
                        name: "phone",
                        id: "phone",
                        className:
                          "border-[1.5px] px-2 py-[9px]  w-full bg-white  rounded-lg border border-gray-200 phone-input-handle",
                        placeholder: t("registrationForm.phone"),
                      }}
                      dropdownClass="custom-phone-dropdown"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="flex flex-col">
                    <Field
                      name="website_url"
                      placeholder={t("registrationForm.websiteUrlPlaceholder")}
                      className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                  <div className="flex flex-col">
                    <Field
                      name="job_title"
                      placeholder={t("registrationForm.jobTitlePlaceholder")}
                      className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                    />
                    <ErrorMessage
                      name="job_title"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="flex flex-col">
                    <Field
                      name="company"
                      placeholder={t("registrationForm.companyPlaceholder")}
                      className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                    />
                    <ErrorMessage
                      name="company"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                <div className="flex flex-col mb-4">
                  <Field
                    as="textarea"
                    name="message"
                    placeholder={t("registrationForm.messagePlaceholder")}
                    rows="4"
                    className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                  />
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="px-6 py-2 bg-text hover:bg-text_hover text-white rounded-lg"
                  >
                    {t("registrationForm.submit")}
                  </button>
                </div>
              </div>
            ) : null}
            {isVisitor || isSpeaker ? (
              <div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                  <div className="flex flex-col">
                    <Field
                      name="first_name"
                      placeholder={t("registrationForm.firstNamePlaceholder")}
                      className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="flex flex-col">
                    <Field
                      name="last_name"
                      placeholder={t("registrationForm.lastNamePlaceholder")}
                      className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                  <div className="flex flex-col mb-4">
                    <Field
                      name="email"
                      type="email"
                      placeholder={t("registrationForm.emailPlaceholder")}
                      className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="flex flex-col" dir="rtl">
                    <PhoneInput
                      country="sa"
                      value={values.phone}
                      onChange={(phone) => setFieldValue("phone", phone)}
                      inputProps={{
                        name: "phone",
                        id: "phone",
                        className:
                          "border-[1.5px] px-2 py-[9px]  w-full bg-white  rounded-lg border border-gray-200 phone-input-handle",
                        placeholder: t("registrationForm.phone"),
                      }}
                      dropdownClass="custom-phone-dropdown"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                  <div className="flex flex-col">
                    <Field
                      name="job_title"
                      placeholder={t("registrationForm.jobTitlePlaceholder")}
                      className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                    />
                    <ErrorMessage
                      name="job_title"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="flex flex-col">
                    <Field
                      name="company"
                      placeholder={t("registrationForm.companyPlaceholder")}
                      className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                    />
                    <ErrorMessage
                      name="company"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                <div className="flex flex-col mb-4">
                  <Field
                    as="textarea"
                    name="message"
                    placeholder={t("registrationForm.messagePlaceholder")}
                    rows="4"
                    className="w-full bg-white rounded-lg border border-gray-200 outline-none p-2 placeholder:text-[#013047a8] font-semibold"
                  />
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="px-6 py-2 bg-text hover:bg-text_hover text-white rounded-lg"
                  >
                    {t("registrationForm.submit")}
                  </button>
                </div>
              </div>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegistrationForm;
