import React, { useEffect, useState } from "react";
import "./Sponser.css";
import { useTranslation } from "react-i18next";
import Carousel from "../../Components/Carousel/Carousel";
import axios from "axios";

const Sponser = () => {
  const { t, i18n } = useTranslation();
  const [Sponsors, setSponsors] = useState([]); // Initialize state for sponsors

  const getSponsors = async (type) => {
    try {
      const res = await axios.get(
        `https://admin.fmexcon.com/api/getNewSponsorApi`
      );
      setSponsors(res?.data?.data);
    } catch (error) {
      console.error(`Error fetching sponsors for type ${type}:`, error);
    }
  };

  useEffect(() => {
    getSponsors();
  }, []); // Empty dependency array ensures this runs only once

  console.log("Sponsors:", Sponsors); // Debugging: Log the final sponsors array

  return (
    <section className="" dir={i18n.language == "en" ? "ltr" : "rtl"}>
      <p
        style={{
          fontWeight: "bold",
          color: "#44add2",
        }}
        className={` mx-16 text-3xl mb-6 ${
          i18n.language == "en"
            ? "lg:text-left md:text-left text-center"
            : "lg:text-right md:text-right text-center"
        }`}
      >
        {t("")}
      </p>
      {[...Array(6)].map((_, index) => {
        // Filter sponsors by type based on the current index
        const filteredSponsors = Sponsors.filter(
          (sponsor) => sponsor.type === index + 1
        );

        return filteredSponsors.length ? (
          <div key={`sponsor-section-${index}`}>
            <p className="text-text font-bold mb-8 mt-10 text-center md:text-4xl text-3xl">
              {index === 0
                ? t("sponsors.partnerSponsor")
                : index === 1
                ? t("sponsors.strategicSponsor")
                : index === 2
                ? t("sponsors.mainSponsor")
                : index === 3
                ? t("sponsors.diamondSponsor")
                : index === 4
                ? t("sponsors.goldSponsor")
                : index === 5
                ? t("sponsors.sliverPartners")
                : ""}
            </p>
            <div className="md:flex md:flex-row flex-col md:gap-12 gap-y-12 gap-x-0 md:m-auto m-4">
              {index === 4 || index === 5 ? (
                // Carousel for Gold and Silver sponsors
                <div className="mb-16">
                  <Carousel sponsers={filteredSponsors} />
                </div>
              ) : (
                // Display sponsor cards for other types
                filteredSponsors.map((sponsor) => (
                  <div
                    key={`sponsor-${sponsor.id}`}
                    className={`card mx-auto px-8 pt-4 text-white md:m-auto my-8  ${
                      index == 0
                        ? "md:w-[42%] w-[100%]"
                        : index == 1
                        ? "md:w-[37%] w-[100%]"
                        : index == 2
                        ? "md:w-[32%] w-[100%]"
                        : index == 3
                        ? "md:w-[30%] w-[100%]"
                        : "w-fit"
                    }`}
                    style={{
                      borderTop:
                        index == 0
                          ? ""
                          : index == 1
                          ? "14px solid #13A89E"
                          : index == 2
                          ? "14px solid #3F54A5"
                          : index == 3
                          ? "14px solid #34C0C4"
                          : "14px solid red",
                    }}
                  >
                    <div className="card-image">
                      <img
                        src={`https://admin.fmexcon.com/public/images/${sponsor.image}`}
                        className="mx-aut md:w-80 w-72"
                        alt="sponsor_image"
                        style={{ height: "100%" }}
                      />
                    </div>
                    <div className="p-4">
                      {i18n.language === "en" ? (
                        <a
                          href={sponsor.url || "#"}
                          className="card-link text-xl hover:opacity-70 text-text"
                          target="_blank"
                          dir="ltr"
                        >
                          Visit Us →
                        </a>
                      ) : (
                        <a
                          href={sponsor.url || "#"}
                          className="card-link text-xl hover:opacity-60 text-text"
                          dir="rtl"
                        >
                          تعرف علينا ←
                        </a>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        ) : null;
      })}
    </section>
  );
};

export default Sponser;
