import React from "react";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className=" relative md:h-[70vh] h-[25vh]" dir="rtl">
      <img
        src="/images/Rectangle 10.png"
        alt="Rectangle 10.png"
        className="relative h-full w-full"
      />
      <div className="absolute right-0 top-1/2 -translate-y-[50%] flex justify-between items-center w-full h-[80vh]">
        <img
          src="/images/banner_hand.png"
          alt="banner_hand.png"
          className=" md:h-60 h-[66px]"
          data-aos="fade-left"
        />
        <div className="md:min-w-[300px] min-w-[130px]">
          <div className="font-bold md:text-[40px] text-xl text-white">
            {t("common.under_theme")}
          </div>
          <div className="md:text-[40px] text-xl text-white md:mt-10 mt-2">
            {t("common.facility_intelligence")}
          </div>
        </div>
        <img
          src="/images/reversed.png"
          alt="banner_hand.png"
          className="md:h-60 h-[66px]"
          data-aos="fade-right"
        />
      </div>
    </section>
  );
};

export default Banner;
